<template>
  <div v-if="$auth.check('ROLE_MIGRATING')">
    <h1 class="warn-message-error" style="text-align: center; margin: 100px 0">
      Ведутся технические работы, попробуйте позже
    </h1>
  </div>
  <div v-else-if="$auth.check('ROLE_MIGRANT')">
    <div style="margin-left: 140px; position: relative;">
      <table cellpadding="0" cellspacing="0" class="prof" style="margin: 0 auto;">
        <tr class="big">
          <td width="180"><strong>Номер лицевого счета</strong></td>
          <td>{{ $user().basic_account }}</td>
        </tr>
        <tr>
          <td><strong>ФИО</strong></td>
          <td>{{ $user().full_name }}</td>
        </tr>
        <tr>
          <td><strong>Адрес</strong></td>
          <td>{{ $user().address }}</td>
        </tr>
        <tr>
          <td><strong>Баланс</strong></td>
          <td>{{ Number($user().balance).toFixed(2) }} руб</td>
        </tr>
      </table>
      <br/>
      <glaz v-if="$auth.check('CAN_GLAZ')" />
    </div>
    <hr style="clear: both; margin-bottom: 20px;" />
    <div style="margin-bottom: 15px; position: relative; margin-left: 140px;">
      <img src="@/assets/img/rtLogo.jpg" style="position: absolute; left: -140px;"/>
      Уважаемый абонент,<br/>
      по техническим причинам <span style="font-weight: bold; color: red;">личный кабинет АльянсТелеком недоступен.</span> Приносим свои извинения за доставленные неудобства.
      <br/>
      <br/>
      <strong>Информируем Вас о том, что объединение сетей «АльянсТелеком» и «Ростелеком» завершено, а услуги связи Вам будут предоставляться под брендом «Ростелеком».</strong> Параметры Вашего тарифного плана останутся без изменений.<br/>
      <br/>
      <strong>Пройдите регистрацию в личном кабинете Ростелеком</strong> <a href="https://lk.rt.ru/" style="color: blue; text-decoration: underline;">LK.RT.RU</a> и управляйте услугами или заказывайте новые. Логин и пароль входа в новый личный кабинет Ростелеком отправлены Вам по СМС.<br/>
      <br/>
      Оплату услуг необходимо производить до 21 числа каждого месяца по Вашему новому номеру счета в Сбербанк Онлайн выбрав провайдера «Ростелеком» или в <a href="https://lk.rt.ru/" style="color: blue; text-decoration: underline;">личном кабинете Ростелеком</a>.
      Ознакомиться с информацией о новых тарифах и услугах Ростелеком Вы можете на сайте <a href="https://primorye.rt.ru/-nahodka-" style="color: blue; text-decoration: underline;">RT.RU</a><br/>
      <br/>
      Телефон службы поддержки Ростелеком: <strong>8 800 100 0 800</strong>
    </div>
  </div>
  <!-- Normal user -->
  <table style="width: 100%; border: none" cellpadding="0" cellspacing="0" v-else>
    <tr>
      <td valign="top">
        <div class="title">
          Личные данные
        </div>
        <table class="prof" style="width: 100%">
          <colgroup>
            <col width="180px"/>
          </colgroup>
          <tr class="big">
            <th style="width: 180px;">Номер лицевого счета</th>
            <td>
              <span class="account-number" v-html="accountNumber" />
            </td>
          </tr>
          <tr class="big" v-if="$user().prev_account_id">
            <th>Старый лицевой счет</th>
            <td>
              <span>{{ $user().prev_account_id }}</span>
              <span title="Для платежей и авторизации в личном кабинете можно использовать и старый и новый ЛС">
                <img src="@/assets/img/warning-icon.png" style="cursor: pointer; margin-left: 5px; width: 15px; vertical-align: bottom;" />
              </span>
            </td>
          </tr>
          <tr class="big" v-if="$user().login && $user().login != $user().basic_account">
            <th>Логин</th>
            <td><span>{{ $user().login }}</span></td>
          </tr>
          <tr>
            <th>{{ $auth.check('ROLE_JUR') ? 'Название компании' : 'Ф.И.О.' }}</th>
            <td>{{ $user().full_name }}</td>
          </tr>
          <tr>
            <th>Адрес</th>
            <td>{{ $user().address }}</td>
          </tr>
          <tr v-if="$auth.check('CAN_ACTIVATE_INET')">
            <th colspan="2" style="text-align: center; vertical-align: middle; padding: 10px;">
              <inet-activation />
            </th>
          </tr>
          <template v-if="$auth.check('ROLE_PHYS') && $user().inet">
            <tr v-if="$user().inet.type">
              <th>Тип подключения</th>
              <td>{{ $user().inet.type }}</td>
            </tr>
            <tr v-if="$user().inet.ip">
              <th>Ваш IP адрес</th>
              <td>{{ $user().inet.ip }}</td>
            </tr>
            <tr>
              <th>Ваш текущий IP адрес</th>
              <td>{{ $user().remote_ip }}</td>
            </tr>
          </template>
        </table>

        <template v-if="$user().tariff">
          <div class="title">
            Тарифный план
          </div>
          <table class="prof" style="width: 100%">
            <tr>
              <th style="width: 180px;">Текущий тариф</th>
              <td>
                <span v-if="$user().tariff.title">{{ $user().tariff.title  }}</span>
                <b class="error" v-else>Неизвестно</b>
                (
                  <span style="color: red" v-if="$user().inet && $user().inet.speed > $user().tariff.params.speed">
                    {{ Math.floor($user().inet.speed / 1024) }} Мбит/с
                  </span>
                  <template v-else>{{ Math.floor($user().tariff.params.speed / 1024) }} Мбит/с</template>
                  за {{ $user().tariff.params.cost }} руб/мес
                )
              </td>
            </tr>
            <tr v-if="$auth.check('ROLE_PHYS')">
              <th>Следующий тариф</th>
              <td>
                <span v-if="$user().tariff.next_title">{{ $user().tariff.next_title  }}</span>
                <b class="error" v-else>Неизвестно</b>
                ( <router-link :to="{name: 'tariffs'}">сменить</router-link> )
              </td>
            </tr>
            <tr v-if="$auth.check('ROLE_PHYS')">
              <th>Следущий расчётный период</th>
              <td>
                {{ nextCalcDate }}
                [осталось {{ nextCalcDiff }}]
              </td>
            </tr>
          </table>
        </template>
        <div v-if="$user().inet && $user().inet.is_social">
          <div style="border: 1px solid red; padding: 5px;">
            У вас подключена услуга «Социальный Интернет», доступ к ресурсам ограничен.
            <router-link :to="{name: 'social-inet'}" style="font-weight: bold;">
              Отключить услугу
            </router-link>
          </div>
          <br/><br/>
        </div>
        <div style="text-align: center" v-if="$auth.check('CAN_GAME_TARIFF')">
          <router-link class="wg-banner" :to="{name: 'game-option'}">
            <img style="max-width: 100%" src="@/assets/img/game-banner.png" />
          </router-link>
          <br/><br/><br/>
        </div>
        <div v-if="$auth.check('ROLE_PHYS')">
          <div v-if="$user().warranty">
            <div class="title warn-message-error">Дополнительные услуги</div>
            <img width="60" align="left" style="margin-right: 20px;" src="@/assets/img/warranty_icon.png" />
            <h3 style="margin-bottom: 10px">{{ $user().warranty.name }}</h3>
            <div>
              {{ $user().warranty.cost }} руб./мес. до {{ warrantyUntil }}
              <router-link :to="{name: 'services'}" custom v-slot="{ navigate }">
                <button style="float: right" type="button" @click="navigate" >Узнать подробности</button>
              </router-link>
            </div>
            <br/><br/><br/>
          </div>
          <div v-if="$auth.check('CAN_SOFTWARE')">
            <div class="title">
              Подписка на антивирус
            </div>
            <img width="60" align="left" style="margin-right: 30px;" src="@/assets/img/software-shield.png" />
            <div class="bg">
              <ul>
                <li>Защитите личные данные и файлы</li>
                <li>Обезопасьте Интернет-платежи</li>
                <li>Заботьтесь о детях с функцией «Родительский контроль»</li>
              </ul>
              <table class="software-table" v-if="$user().software.length">
                <tr>
                  <td>
                    Ваши текущие подписки:
                    <br><br>
                  </td>
                </tr>
                <tr v-for="v in $user().software" :key="v.id">
                  <td class="activation_code">
                    <form :action="v.pd_link_win" method="GET" target="_blank" v-if="v.pd_link_win">
                      <input type="submit" value="Скачать антивирус с настроенным ключом активации"
                        style="padding: 15px;white-space: pre-line;width: 20em;font-weight: bold;margin-bottom: 15px;"
                      >
                      <br/>
                    </form>
                    <strong style="display: block; margin-bottom: 3px;">{{ v.name }}</strong>
                    <template v-if="v.product_key">
                      код активации: <strong>{{ v.product_key }}</strong>
                    </template>
                    <div v-if="v.login && v.password">
                      логин: <strong>{{ v.login }}</strong>, пароль: <strong>{{ v.password }}</strong>
                    </div>
                    <br><br>
                  </td>
                </tr>
                <tr>
                  <td>
                    <router-link style="font-weight: bold;" :to="{name: 'software'}">
                      Управление подписками&nbsp;&rArr;
                    </router-link>
                  </td>
                  <td class="terminate"></td>
                </tr>
              </table>
              <div class="form" style="text-align: right" v-else>
                <router-link :to="{name: 'software'}" custom v-slot="{ navigate }">
                  <input type="button" @click="navigate" value="Оформить подписку" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <glaz v-if="$auth.check('CAN_GLAZ')" />
      </td>
      <td style="width: 22px"></td>
      <td style="width: 360px" valign="top">
        <div class="title">
          Состояние счета
        </div>
        <table class="bill_tbl" style="width: 100%">
          <colgroup>
            <col width="210px">
          </colgroup>
          <tr>
            <td>Баланс лицевого счёта:</td>
            <th>{{ $user().balance_show !== undefined ? $user().balance_show : Number($user().balance).toFixed(2) }} руб.</th>
          </tr>
          <template v-if="$auth.check('ROLE_PHYS')">
            <tr v-if="!$user().is_blocked && $user().days_before_block">
              <td>До блокировки</td>
              <th>~ {{ $user().days_before_block }} дн.</th>
            </tr>
            <tr v-if="$user().advised_payment">
              <td >Рекомендуемый платеж</td>
              <th>{{ $user().advised_payment }} руб.</th>
            </tr>
            <tr class="discount" v-if="$user().discount">
              <th v-if="$user().discount.type === 'discount'">
                Скидка {{ $user().discount.value }}% на абон. плату до
              </th>
              <th v-else>
                {{ $user().discount.expire ? 'Абонемент оплачен до' : 'Подключен абонемент' }}
              </th>
              <th>{{ discountUntil }}</th>
            </tr>
          </template>
          <template v-if="$user().write_offs && $user().write_offs.length">
            <tr>
              <th colspan="2" style="background-color: #F7F7F7;">Списания</th>
            </tr>
            <tr v-for="write_off in $user().write_offs" :key="write_off.id">
              <td>{{ write_off.service_name }}</td>
              <th>{{ Number(write_off.discounted).toFixed(2) }} руб.</th>
            </tr>
          </template>
        </table>
        <div style="border: 1px solid #bbb; margin: 2px; padding-left: 10px; padding-bottom: 10px" v-if="$auth.check('CAN_CHANGE_BLOCK')">
          <h3 class="black" style="text-align: center">
            <span v-if="$user().block_info.is_vol">Добровольная блокировка</span>
            <span v-else>Переход в состояние блокировки</span>
          </h3>
          <img src="@/assets/img/lock.png" style="width: 50px; float: right; margin-right: 10px"/>
          <p v-if="$user().is_blocked">
            Доступ в интернет приостановлен.
            Абонентская плата за интернет в период блокировки не взимается.
          </p>
          <p v-else>
            Приостановить доступ в интернет на срок от 10 дней до 6 месяцев.
            Абонентская плата за интернет в период блокировки не взимается.
          </p>
          <br/>
          <div style="text-align: center;">
            <router-link :to="{name: 'block'}" custom v-slot="{ navigate }">
              <input type="button" @click="navigate" :value="$user().block_info.is_vol ? 'Снять блокировку' : 'Узнать подробности'">
            </router-link>
          </div>
        </div>

        <div v-if="$auth.check('ROLE_PHYS')">
          <promo-code-form v-if="$auth.check('ROLE_ATK')" />

          <div style="text-align: center; margin: 10px 0 10px 0;" v-if="$auth.check('ALT_ABONEMENT_BANNER')">
            <a href="https://inetvl.ru/oborudovanie/ymniye-kolonki/" target="_blank" class="wg-banner">
              <img src="@/assets/img/smart-speaker.png" />
            </a>
          </div>
          <div style="text-align: center; margin: 10px 0 10px 0;" v-else-if="$auth.check('CAN_ABONEMENT')">
            <router-link :to="{name: 'abonement'}">
              <img src="@/assets/img/abonement_2.png" />
            </router-link>
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import InetActivation from '@/components/InetActivation'
import PromoCodeForm from '@/components/PromoCodeForm'
import Glaz from '@/components/Glaz'
import dateFormat from 'dateformat'

export default {
  components: { InetActivation, PromoCodeForm, Glaz },
  computed: {
    accountNumber() {
      return '<span>' + new String(this.$user().account).replace(/ /g, '</span><span>') + '</span>'
    },
    nextCalcDate() {
      return dateFormat(new Date(this.$user().tariff.next_calc_time * 1000), 'd mmmm yyyy, HH:MM')
    },
    nextCalcDiff() {
      return Math.floor(this.$user().tariff.next_calc_diff / 86400) + ' дн. ' +
             Math.floor((this.$user().tariff.next_calc_diff % 86400) / 3600) + ' ч.'
    },
    discountUntil() {
      if (!this.$user().discount?.expire) {
        return null
      }
      return dateFormat(new Date(this.$user().discount.expire * 1000), 'dd.mm.yyyy')
    },
    warrantyUntil() {
      if (!this.$user().warranty?.expire) {
        return null
      }
      return dateFormat(new Date(this.$user().warranty.expire * 1000), 'dd.mm.yyyy')
    },
  },
}
</script>

<style scoped>
.account-number > :deep(span) {
  margin-right: 4px;
}
</style>
